@tailwind base;
@tailwind components;
@tailwind utilities;

.form-style {
  @apply w-full border-border font-normal bg-opacity-70  
   rounded-md bg-background focus:border-primary focus:ring-primary;
}
.form-style-light {
  @apply w-full bg-lightDark text-sm placeholder:text-white placeholder:text-opacity-60 border-border 
 border-opacity-60 font-normal bg-opacity-70 rounded-md focus:border-primary focus:ring-primary;
}

.form-style-with-white-background {
  @apply w-full border-border font-normal
  rounded-md bg-white focus:border-primary focus:ring-primary;
}
